import { ReactNode } from "react";
import { Form, Modal } from "react-bootstrap";

type FormModalProps = {
  header: ReactNode;
  footer: ReactNode;
  onSubmit?: () => {};
  show: boolean;
  handleClose?: () => void;
  id: string;
  children: ReactNode;
  classes?: {
    modal?: string;
    header?: string;
    body?: string;
    footer?: string;
    dialog?: string;
  };
};

const FormModal = ({
  header,
  footer,
  onSubmit,
  show,
  handleClose,
  id,
  children,
  classes,
}: FormModalProps) => {
  return (
    <Modal
      centered
      className={classes?.modal}
      dialogClassName={classes?.dialog}
      show={show}
      onHide={handleClose}
    >
      <Form onSubmit={onSubmit} id={id}>
        <Modal.Header closeButton className={classes?.header}>
          {header}
        </Modal.Header>
        <Modal.Body className={classes?.body}>{children}</Modal.Body>
        <Modal.Footer className={classes?.footer}>{footer}</Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormModal;
