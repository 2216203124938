import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AssignDeviceMutation,
  UnassignDeviceMutation,
  unassignFleetMutation,
  activateDeviceMutation,
  assignToFleetMutation,
  deactivateDeviceMutation,
} from "../mutations/device.mutation";
import { useToastContext } from "../context/ToastContext";
import { IUnassignDevice } from "../services/devices.service";

interface UseMutationDeivceProps {
  onSuccessActivate?: () => void;
  onSuccessDeactivate?: () => void;
}

function useMutationDevice({ onSuccessActivate, onSuccessDeactivate }: UseMutationDeivceProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateAssignDevice } = useMutation(AssignDeviceMutation());
  const { mutate: mutateUnassignDevice } = useMutation(UnassignDeviceMutation());

  async function unmute(deviceId: string): Promise<void> {
    mutateAssignDevice(deviceId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });
        queryClient.invalidateQueries({ queryKey: ["alldevices"] });
        addToast({ type: "POSITIVE", content: "Unmuted device successfully" });
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Unmute device failed" });
      },
    });
  }

  async function mute({ deviceId, unpair }: IUnassignDevice): Promise<void> {
    mutateUnassignDevice(
      { deviceId, unpair },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["devices"] });
          queryClient.invalidateQueries({ queryKey: ["alldevices"] });
          addToast({ type: "POSITIVE", content: `${unpair ? "Unpaired" : "Muted"} device successfully` });
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `${unpair ? "Unpair" : "Mute"} device failed` });
        },
      }
    );
  }

  const { mutate: assignToFleet, isLoading: isLoadingAssignToFleet } = useMutation(
    assignToFleetMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });
        queryClient.invalidateQueries({ queryKey: ["alldevices"] });
        addToast({ type: "POSITIVE", content: "Assigned device to fleet successfully" });
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed assigning device to fleet" });
      },
    })
  );

  const { mutate: unassignFleet, isLoading: isLoadingUnassignFleet } = useMutation(
    unassignFleetMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });
        queryClient.invalidateQueries({ queryKey: ["alldevices"] });
        addToast({ type: "POSITIVE", content: "Unassigned fleet from device successfully" });
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed unassigning fleet from device" });
      },
    })
  );

  const { mutate: activate, isLoading: isLoadingActivate } = useMutation(
    activateDeviceMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });
        queryClient.invalidateQueries({ queryKey: ["alldevices"] });
        addToast({ type: "POSITIVE", content: "Activated device successfully" });
        onSuccessActivate?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed activating device" });
      },
    })
  );

  const { mutate: deactivate, isLoading: isLoadingDeactivate } = useMutation(
    deactivateDeviceMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["devices"] });
        queryClient.invalidateQueries({ queryKey: ["alldevices"] });
        addToast({ type: "POSITIVE", content: "Deactivated device successfully" });
        onSuccessDeactivate?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed deactivating device" });
      },
    })
  );

  return {
    unmute,
    mute,
    assignToFleet,
    isLoadingAssignToFleet,
    unassignFleet,
    isLoadingUnassignFleet,
    activate,
    isLoadingActivate,
    deactivate,
    isLoadingDeactivate,
  };
}

export default useMutationDevice;
