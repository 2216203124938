import { ReactNode} from "react";
import { ReactComponent as FSCloudLogo } from "../../assets/FSCloud.svg";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import useQueryOwnFleet from "../../hooks/useQueryOwnFleet";
import { getIsFleetRole } from "../../util/roles.util";
import { addBase64Prefix } from "../../util/shared.util";
import DefaultFleetIcon from "../../assets/icons/FleetWhiteBg.svg";
import DefaultTspIcon from "../../assets/icons/TspWhiteBg.svg";
import CipiaIcon from "../../assets/mocks/CipiaIcon.svg";
import { isCipia, isTsp } from "../../context/TspFleetContext/tsp-fleet-context.util";
import { useSoftware } from "../../hooks/useSoftware";
import refershIcon from "../../assets/Refresh.svg";
import { useLastUpdateContext } from "../../context/LastUpdateContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";

interface HeaderLayoutProps {
  middleComponent?: ReactNode;
}

function HeaderLayout({ middleComponent }: HeaderLayoutProps) {
  const version = useSoftware();
  const role = useAuthContext().loggedUser?.role;
  const { selected } = useTspFleetContext();
  const { data: ownFleet } = useQueryOwnFleet();
  const { time } = useLastUpdateContext();
  const { getFormattedLocalDate } = useFormattedLocalDate();

  let logo: string | undefined | null = undefined;
  let shownName: string | undefined = undefined;

  const isFleetRole = getIsFleetRole(role);
 

  if (isFleetRole) {
    logo = ownFleet?.logo ? addBase64Prefix(ownFleet.logo) : DefaultFleetIcon;
    shownName = ownFleet?.companyFriendlyName;
  } else {
    if (selected) {
      if (!isCipia(selected) && selected.logo) {
        logo = addBase64Prefix(selected.logo);
      } else if (isCipia(selected)) {
        logo = CipiaIcon;
      } else if (isTsp(selected)) {
        logo = DefaultTspIcon;
      } else {
        logo = DefaultFleetIcon;
      }
    }
    shownName = selected && isCipia(selected) ? "Cipia" : selected?.companyFriendlyName;
  }


  return (
    <div className="d-flex flex-column h-100">
      <div
        className="p-3 d-flex align-items-center justify-content-between flex-grow-0"
        style={{ height: "4.5rem", borderBottom: "1px solid #EFEFEF" }}
      >
        <div className="d-flex align-items-center gap-2">
          <img src={logo} alt="logo" height="32px" />
          {shownName}
        </div>
        { middleComponent && <div className="absolute-centered-axis-x">{middleComponent}</div>}
        <div>
          <FSCloudLogo />
          <br></br>
          <div className="text-center">
            {version.data?.version} {!middleComponent && <span className="ms-1">
            <OverlayTrigger placement="bottom" overlay={<Tooltip> <>{getFormattedLocalDate(time)}</></Tooltip>}>
              <img src={refershIcon} alt="logo" height="12px" />
            </OverlayTrigger>
        </span>} 
          </div>
        </div>
      </div>
      <div className="flex-grow-1 position-relative overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}

export default HeaderLayout;
