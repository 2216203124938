import FormInput from "../../form/FormInput/FormInput";
import { FormattedMessage, useIntl } from "react-intl";
import FormModal from "../../form/FormModal/FormModal";
import { FormGroup } from "../../form/FormGroup/FormGroup";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as AddProvisionDeivicesIcon } from "../../../assets/icons/AddProvisionDevice.svg";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { ReactComponent as CheckVIcon } from "../../../assets/icons/CheckV.svg";
import { Color } from "../../../constants";
import useDevice from "../../../hooks/useDevice";
import { StandardOption } from "../../ReactSelect/ReactSelect";
import CustomUnderlinedText from "../../Text/CustomUnderlinedText";
import useQueryTsps from "../../../hooks/useQueryTsps";
import useQueryFleets from "../../../hooks/useQueryFleets";
import { getTspIds } from "../../../context/TspFleetContext/tsp-fleet-context.util";
import { ProvisionDevices } from "../../../api/data-contracts";
import { Form } from 'react-bootstrap';
import styles from "../DevicesTable.module.scss";
import Arrow, { Direction } from "../../../icons/Arrow";
import ConfirmFormChangeModal from "../../RulesTable/modals/ConfirmFormChangeModal";

const formModalClasses = {
    header: "modal-form-header",
    footer: "modal-form-footer",
    dialog: "modal-form-dialog",
    body: "modal-form-body",
};

interface ProvisionDeviceFormInputs {
    tsp?: StandardOption;
    fleet?: StandardOption;
    deviceIds?: string;
    mediaStorageLimit?: number;
    mediaRetentionTime?: number;
    password?: string;
    successfulProvisionDevices?: string;
    failedProvisionDevices?: string;
}

interface AddDeviceModalProps {
    onClickOk: () => void;
    onClickCancel: () => void;
}



function AddDeviceModal({
    onClickCancel,
}: AddDeviceModalProps) {
    const [isSuccessProvision, setIsSuccessProvision] = useState(false);
    const [successfulDevices, setSuccessfulDevices] = useState("");
    const [failedDevices, setFailedDevices] = useState("");
    const intl = useIntl();
    const { provisionDevices } = useDevice({ onSuccessProvisionDevices: () => setIsSuccessProvision(true) });

    const { control, handleSubmit, setValue, watch, formState: { isValid, dirtyFields, errors } } = useForm<ProvisionDeviceFormInputs>({
        reValidateMode: "onChange",
        defaultValues: {
            mediaRetentionTime: 60,
            mediaStorageLimit: 10000,
        }
    });

    const { data: dataTsps } = useQueryTsps({ enabled: true });
    const tspIds = useMemo(() => getTspIds(dataTsps?.data || []), [dataTsps?.data]);
    const { data: dataFleets } = useQueryFleets({ enabled: true, tspIds: tspIds });

    const tspOptions = useMemo(() => {
        return dataTsps?.data?.map(tsp => ({ value: tsp.id!.toString(), label: tsp.companyFriendlyName! }))
    }, [dataTsps?.data]);


    const selectedTsp = watch("tsp");
    const fleetsOptions = useMemo(() => {
        if (!selectedTsp) {
            return [];
        }
        return dataFleets?.data
            ?.filter(fleet => fleet.tspId!.toString() === selectedTsp.value.toString())
            .map(fleet => ({ value: fleet.id!.toString(), label: fleet.companyFriendlyName! }));
    }, [dataFleets?.data, selectedTsp]);


    useEffect(() => {
        if (tspOptions?.length === 1) {
            setValue("tsp", tspOptions[0]);
        }
    }, [tspOptions, setValue]);

    useEffect(() => {
        setValue("fleet", undefined);
    }, [selectedTsp, setValue]);

    useEffect(() => {
        setValue("successfulProvisionDevices", successfulDevices);
        setValue("failedProvisionDevices", failedDevices);
    }, [successfulDevices, setValue, failedDevices]);

    const onClipboardButtonClick = async (type: string) => {
        if (type === "successfulProvisionDevices") {
            await navigator.clipboard.writeText(successfulDevices);
        }
        else {
            await navigator.clipboard.writeText(failedDevices);
        }
    };


    const onSubmit = async ({ tsp, fleet, deviceIds, mediaStorageLimit, mediaRetentionTime, password }: ProvisionDeviceFormInputs) => {
        if (!isValid)return;

        const deviceData: ProvisionDevices = {
            tspId: Number(tsp?.value),
            fleetId: Number(fleet),
            deviceIds: deviceIds,
            password: password,
            mediaRetentionTimeInDays: mediaRetentionTime,
            perDeviceMediaStorageLimitInMB: mediaStorageLimit
        };
        const result = await provisionDevices(deviceData);
        setSuccessfulDevices(result.successfulDevices);
        setFailedDevices(result.failedDevices);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isFleetOpen, setIsFleetOpen] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    const handleCancel = () => {
        if (Object.keys(dirtyFields).length > 0) {
            setShowAlert(true);
            return;
        }
        onClickCancel();
    }

    const ProvisionResults = () => {
        return (
            <>
                <CustomUnderlinedText text={intl.formatMessage({
                    id: "DEVICE_PROVISION_SUCCESS_MESSAGE",
                    defaultMessage: `Successfully provisioned ${successfulDevices.trim().length === 0 ? 0 : successfulDevices.split(',').map(device => device.trim()).length} devices`,
                },
                    {
                        count: successfulDevices.trim().length === 0 ? 0 : successfulDevices.split(',').map(device => device.trim()).length
                    })} type="green" />
                <FormGroup
                    key="successfulProvisionDevices"
                    input={
                        <FormInput
                            type="text"
                            input={{
                                disabled: false,
                                type: "textarea",
                                textareaRows: 6,
                            }}
                            rules={{ required: true }}
                            name="successfulProvisionDevices"
                            control={control}

                        />
                    }
                />
                <div className="d-flex justify-content-end mt-2">
                    <ModalMainButton type="button" disabled={successfulDevices.length===0}  onClick={() => onClipboardButtonClick("successfulProvisionDevices")}>
                        <FormattedMessage id="COPY_IDS" defaultMessage="Copy IDs" />
                    </ModalMainButton>
                </div>

                <CustomUnderlinedText text={intl.formatMessage({
                    id: "DEVICE_PROVISION_FAIL_MESSAGE",
                    defaultMessage: `${failedDevices.trim().length === 0 ? 0 : failedDevices.split(',').map(device => device.trim()).length} devices were not provisioned`,
                },
                    {
                        count: failedDevices.trim().length === 0 ? 0 : failedDevices.split(',').map(device => device.trim()).length
                    })} type="red" />
                <FormGroup
                    key="failedProvisionDevices"
                    input={
                        <FormInput
                            type="text"
                            input={{
                                disabled: false,
                                type: "textarea",
                                textareaRows: 6,
                            }}
                            rules={{ required: true }}
                            name="failedProvisionDevices"
                            control={control}
                        />
                    }
                />
                <div className="d-flex justify-content-end mt-2">
                    <ModalMainButton type="button"   disabled={failedDevices.length===0} onClick={() => onClipboardButtonClick("successfulProvisionDevices")}>
                        <FormattedMessage id="COPY_IDS" defaultMessage="Copy IDs" />
                    </ModalMainButton>
                </div>
            </>
        );
    };


    return (
        <>
            <FormModal
                show
                id="addDriverModal"
                handleClose={handleCancel}
                onSubmit={handleSubmit(onSubmit)}
                classes={formModalClasses}
                header={
                    <>
                        <AddProvisionDeivicesIcon />
                        <div className="p-2">
                            {isSuccessProvision ? <FormattedMessage id="PROVISION_RESULTS" /> : <FormattedMessage id="PROVISION_DEVICES" />}
                        </div>
                    </>
                }
                footer={
                    <div className="d-flex align-items-center gap-2">
                        <ModalSecondaryButton disabled={false} type="button" onClick={onClickCancel}>
                            <FormattedMessage id="CLOSE" defaultMessage="Close" />
                        </ModalSecondaryButton>
                        {!isSuccessProvision && <ModalMainButton icon={<CheckVIcon fill={Color.DARKER_GREEN} />} type="submit" disabled={!isValid}>
                            <FormattedMessage id="PROVISION" defaultMessage="Provision" />
                        </ModalMainButton>}
                    </div>
                }
            >
                {isSuccessProvision ? <ProvisionResults /> : <>
                    <CustomUnderlinedText text={intl.formatMessage({
                        id: "ACCOUNTS_SELECTION",
                        defaultMessage: "Accounts Selection",
                    })} type="normal" />
                    <Row className="mb-3 mt-3">
                        <Col md={6}>
                            <Form.Group controlId="tsp">
                                <Form.Label className="font-md mb-0 app-form-label" ><FormattedMessage id="TSP" defaultMessage="TSP" /></Form.Label>
                                <Controller
                                    name="tsp"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div id="tsp" key={"tsp"} className={styles["dropdown-wrapper"]}>
                                            <Form.Control disabled={tspOptions?.length===1} as="select" {...field} value={field.value?.value} onBlur={() => setIsOpen(false)}
                                                onChange={(e) => {
                                                    const selectedOption = tspOptions!.find(
                                                        (option) => option.value === e.target.value
                                                    );
                                                    field.onChange(selectedOption || null);
                                                }}
                                                onFocus={() => setIsOpen(true)} className={`${errors.tsp ? 'is-invalid' : ''}`}>
                                                <option value="">{intl.formatMessage({
                                                    id: "SELECT",
                                                    defaultMessage: "Select...",
                                                })}</option>
                                                {(tspOptions || []).map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <span className={styles["dropdown-arrow"]}> <Arrow color={isOpen ? Color.CIPIA_BLUE : Color.BLUE_GRAY_2} direction={Direction.Down} /></span>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="fleet">
                                <Form.Label className=" font-md mb-0 app-form-label"><FormattedMessage id="FLEET" defaultMessage="Fleet" /></Form.Label>
                                <Controller
                                    name="fleet"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div id="tsp" key={"fleet"} className={styles["dropdown-wrapper"]}>
                                            <Form.Control as="select" {...field} value={field.value?.label} onBlur={() => setIsFleetOpen(false)}
                                                onFocus={() => setIsFleetOpen(true)}
                                                className={`${errors.fleet ? 'is-invalid' : ''}`}>
                                                <option value="">{intl.formatMessage({
                                                    id: "SELECT",
                                                    defaultMessage: "Select...",
                                                })}</option>
                                                {(fleetsOptions || []).map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <span className={styles["dropdown-arrow"]}> <Arrow color={isFleetOpen ? Color.CIPIA_BLUE : Color.BLUE_GRAY_2} direction={Direction.Down} /></span>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <CustomUnderlinedText text={intl.formatMessage({
                        id: "DEVICE_IDS",
                        defaultMessage: "Device IDs",
                    })} type="normal" />
                    <FormGroup
                        key="deviceIds"
                        input={
                            <>
                                <FormInput
                                    type="text"
                                    input={{
                                        disabled: false,
                                        type: "textarea",
                                        placeholder: intl.formatMessage({
                                            id: "DEVICE_PROVISION_PASTE_DESCRIPTION",
                                            defaultMessage: "Paste device IDs separated by commas (E423110165, E42311219)",
                                        }),
                                        textareaRows: 6,
                                    }}
                                    name="deviceIds"

                                    rules={{
                                        required: true,
                                        validate: (deviceIds) => {
                                            if (!deviceIds) {
                                                return "Device IDs are required";
                                            }

                                            const deviceIdsString = deviceIds as string;

                                            const deviceNames = deviceIdsString.split(',').map((deviceId) => deviceId.trim());
                                            const invalidDevices = deviceNames.filter(
                                                (deviceName) => !/^E\d{9}$/.test(deviceName)
                                            );

                                            if (invalidDevices.length > 0) {

                                                return intl.formatMessage({
                                                    id: "INVALID_DEVICE_IDs",
                                                    defaultMessage: `Invalid device IDs: ${invalidDevices.join(', ')}`,
                                                },
                                                    {
                                                        deviceIdNames: invalidDevices.join(', ')
                                                    })

                                            }

                                            if (deviceNames.length > 1000) {
                                                return intl.formatMessage({
                                                    id: "DEVICE_IDS_MAX_LENGTH",
                                                    defaultMessage: `Only 1000 devices can be provisioned at a time.`,
                                                })
                                            }

                                            return true;
                                        },
                                    }}

                                    control={control}

                                />
                                {errors.deviceIds && (
                                    <div className="text-danger">
                                        {errors.deviceIds.message}
                                    </div>
                                )}
                            </>

                        }
                    />
                    <Row className="mt-3 mb-3 align-items-end">
                        <Col md={4}>
                            <FormGroup
                                className="flex-grow-1"
                                label={<FormattedMessage id="MEDIA_STORAGE_LIMIT" defaultMessage="Media Storage Limit" />}
                                key="mediaStorageLimit"
                                input={
                                    <FormInput
                                        type="text"
                                        name="mediaStorageLimit"
                                        control={control}
                                        rules={{ required: true }}
                                        input={{ disabled: false, showSuffixText: true, suffixText: 'MB', isNumeric: true }}
                                    />
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <FormGroup
                                className="flex-grow-1"
                                label={<FormattedMessage id="MEDIA_RETENTION_TIME" defaultMessage="Media Retention Time" />}
                                key="mediaRetentionTime"
                                input={
                                    <FormInput
                                        type="text"
                                        name="mediaRetentionTime"
                                        control={control}
                                        rules={{ required: true }}
                                        input={{ disabled: false, showSuffixText: true, suffixText: 'Days', isNumeric: true }}
                                    />
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <FormGroup
                                className="flex-grow-1"
                                label={<FormattedMessage id="PASSWORD" defaultMessage="Password" />}
                                key="password"
                                input={
                                    <>
                                    <FormInput
                                        type="text"
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: true,
                                            validate: (password) => {
                                                const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
                                                if (!regex.test(password as string)) {
                                                    return intl.formatMessage({
                                                        id: "PASSWORD_VALIDATION",
                                                        defaultMessage: `Password must be at least 10 characters, include uppercase, lowercase, a number, and a special character.`,
                                                    })
                                                }

                                                return true;
                                            },
                                        }}
                                        input={{ type: "password", disabled: false }}
                                    />
                                  
                                    </>
                                }
                            />
                        </Col>

                    </Row>
                    <Row>
                    {errors.password && (
                                        <div className="text-danger">
                                            {errors.password.message}
                                        </div>
                                    )}
                    </Row>
                </>}

                <ConfirmFormChangeModal onClickNo={() => setShowAlert(false)} show={showAlert} onClickYes={onClickCancel} />
            </FormModal >
        </>
    );
}

export default AddDeviceModal;
