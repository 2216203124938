import { AxiosResponse } from "axios";
import { DeviceManagement } from "../api/DeviceManagement";
import { FleetSenseDevice } from "../api/data-contracts";
import { FilterParam, NullablePagingQueryParams, QueryParams, ServiceGetResult } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

const devicesApi = new DeviceManagement();
addAuthHandling(devicesApi);

export type DevicesSortFilterParamName =
  | "id"
  | "status"
  | "vehicleId"
  | "time"
  | "ownerEmail"
  | "tspId"
  | "fleetId"
  | "hasVehicle"
  | "eventDateTime"
  | "fleetAssigned"
  | "added"
  | "model"
  | "year"
  | "deviceType"
  | "assignFleetDate"
  | "configFileVersion"
  | "messageid"
  | "isActive"
  | "(id|vehicleId)"
  | "eventDriverid"
  | "driverId";

export type DevicesSortingParam = {
  param: DevicesSortFilterParamName;
  descending?: boolean;
};

export type DevicesFilterParam = FilterParam<DevicesSortFilterParamName>;
export type GetDeviceParams = {deviceId:string, includeDrivers:boolean};
export type GetAllDevicesParams = GetDevicesNullablePagingParams

export type GetDevicesParams = QueryParams<DevicesSortingParam, DevicesFilterParam> & {includeInstallationReport:boolean};
export type GetDevicesResult = ServiceGetResult<FleetSenseDevice[]>;
export interface IUnassignDevice {
  deviceId: string;
  unpair?: boolean;
}
export interface AssignToFleetParams {
  deviceId: string;
  fleetId: number;
}

export type GetDevicesNullablePagingParams ={includeInstallationReport: boolean} & NullablePagingQueryParams<DevicesSortingParam>

export const devicesService = {
  getDevices: async ({ paging, sorts, filters, includeInstallationReport,geocoding }: GetDevicesNullablePagingParams): Promise<GetDevicesResult> => {
    const result = await devicesApi.getDevices({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging?.pageIndex,
      pageSize: paging?.pageSize,
      includeInstallationReport,
      geocoding: geocoding
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }

    return {
      data: result.data.data,
      pageCount,
      totalCount: header?.totalCount,
    };
  },

  getAllDevices: async ({sorts, filters, includeInstallationReport,geocoding }: GetDevicesNullablePagingParams): Promise<GetDevicesResult> => {
    const result = await devicesApi.getDevices({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      includeInstallationReport,
      geocoding: geocoding
    });

    const header = result.data.header;
    
    return {
      data: result.data.data,
      totalCount: header?.totalCount
    };
  },

  getDevice: async ({deviceId, includeDrivers} : GetDeviceParams): Promise<FleetSenseDevice> => {
    const device = await devicesApi.getDevice(deviceId, { includeDrivers });

    return device.data;
  },

  assignDevice: async (deviceId: string): Promise<AxiosResponse> => {
    return await devicesApi.assignDevice(deviceId);
  },

  unassignDevice: async ({ deviceId, unpair }: IUnassignDevice): Promise<AxiosResponse> => {
    return await devicesApi.unassignDevice(deviceId, { unpair });
  },

  assignToFleet: async ({ deviceId, fleetId }: AssignToFleetParams): Promise<AxiosResponse<void>> => {
    return await devicesApi.assignToFleet(deviceId, fleetId);
  },

  unassignFleet: async (deviceId: string): Promise<AxiosResponse<void>> => {
    return await devicesApi.unassignFleet(deviceId);
  },

  activateDevice: async (deviceId: string): Promise<AxiosResponse<void>> => {
    return await devicesApi.activateDevice(deviceId);
  },
  deactivateDevice: async (deviceId: string): Promise<AxiosResponse<void>> => {
    return await devicesApi.deactivateDevice(deviceId);
  },

  provisionDevices: async (data: any): Promise<any> => {
    const result =  await devicesApi.provisionDevices(data);
    return {
      data: result.data
    }
  },

};
